@import "varibals";

.header-member-list-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: calc(100vh - 160px);

  > div {
    padding-bottom: 10px;
    // border-bottom: 1px solid rgba(229, 229, 229, 1);
  }

  .header-ledger-post-commit-form {
    div.full-width {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row-reverse;

      & > input {
        padding: 15px 0px;
        font-weight: 600;
      }
    }
  }

  svg.fa-search {
    background-color: transparent;
    font-size: 20px;
    color: #555 !important;
    opacity: 1 !important;
    cursor: pointer;
    margin-left: 16px;
    margin-right: 20px;
    width: 0.9em;
    height: 0.9em;

    &.disabled:hover {
      color: #555 !important;
      cursor: initial;
    }

    &:hover {
      color: $predStatusBlue !important;
    }
  }

  .header-ledger-title {
    border-top: 1px solid #e5e5e5;
    padding-left: 15px !important;
    padding-bottom: 0px !important;
    padding-top: 5px !important;
    margin: unset !important;
  }

  .accordion {
    font-size: 14px;
    color: $colorLight;
    max-height: 780px;

    .main-title {
      display: flex;
      align-items: center;

      span {
        flex-grow: 1;
      }
    }

    .title {
      color: #7f7f7f !important;
      font-weight: normal;
      line-height: normal;
      display: flex;
      align-items: center;
      border-top: unset !important;

      &.active .main-title {
        color: $predStatusBlue !important;
      }

      &.active {
        color: rgba(0, 0, 0, 0.95) !important;
        background-color: rgba(203, 230, 255, 0.7) !important;
        font-weight: 700 !important;
      }

      &.active > .main-title > svg {
        color: rgba(0, 0, 0, 0.95) !important;
      }

      &.active svg {
        color: rgba(0, 0, 0, 0.95) !important;
      }

      &.active span {
        font-weight: 700 !important;
      }

      & > * {
        margin: 0 5px;
        flex-grow: 1;
      }

      & > i.dropdown.icon {
        display: none !important;
      }

      &:hover {
        background-color: rgba(203, 230, 255, 0.7) !important;
      }

      svg {
        color: rgba(0, 0, 0, 0.95) !important;
        font-size: 18px;
        flex-basis: 15%;
        flex-grow: 0;
        margin-left: 20px;

        &.task-force {
          margin-left: 16px;
        }
        &.fa-key-skeleton {
          margin-left: -8px;
          margin-right: -43px;
        }
        &.member-icon {
          visibility: hidden;
        }
      }

      svg.fa-angle-up,
      svg.fa-angle-down {
        font-size: 20px;
      }
    }

    .content {
      padding: unset !important;
      color: rgba(0, 0, 0, 0.95) !important;

      &.active {
        background-color: rgba(203, 230, 255, 0.7) !important;
      }

      & > .accordion {
        margin-top: 0;
      }

      .content-option {
        border-radius: 5px;
        cursor: pointer;
        padding: 10px 45px 10px 74px;
        color: rgba(0, 0, 0, 0.95) !important;
        display: flex;
        align-items: center;
        font-weight: 700;
        // margin-bottom: 10px;

        &.disabled {
          color: rgba(0, 0, 0, 0.2) !important;
          cursor: default;

          & > svg {
            color: rgba(0, 0, 0, 0.2) !important;
          }

          &:hover {
            background-color: inherit;
          }
        }

        & > svg {
          color: rgba(0, 0, 0, 0.95) !important;
          flex-basis: 20%;
          display: none;
        }

        & > span {
          flex-grow: 1;
          font-size: 14px;
          font-weight: 500;
        }

        &:hover {
          background-color: #eff9fd !important;
        }
      }

      & svg {
        font-size: 22px;
        color: $colorThird;
      }

      .icon.button {
        font-size: 14px !important;
        background-color: transparent;
        font-size: 1.85rem;
        color: $colorLight;
        background-color: transparent;
        padding: 15px 30px;

        &:hover {
          color: $predStatusBlue;
        }

        &:hover .dropdown-wrapper svg {
          color: $predStatusBlue;
        }

        & > .dropdown-wrapper {
          svg {
            margin-left: 5px;
            color: $colorLight;
          }
        }

        .icon {
          background-color: transparent;
          font-size: 20px;
        }
      }
    }
  }

  .ui.action.input.full-width {
    border: 1px solid transparent;
    border-bottom: 1px solid #d4d4d4;

    input {
      &::placeholder {
        color: rgba(0, 0, 0, 0.6) !important;
        font-size: 14px;
      }

      &:focus::placeholder {
        color: rgba(0, 0, 0, 0.95) !important;
      }
    }

    button {
      color: rgba(0, 0, 0, 0.95);

      &:hover {
        color: $predStatusBlue;
      }
    }

    &-search {
      padding-left: 22px;
    }
  }

  .ui.segment {
    background-color: transparent;
    box-shadow: none;
    border: 0;
    padding: 0;
  }

  & > div {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    & > .active.tab {
      flex-grow: 1;
      height: initial;

      & > div {
        width: 100%;
      }
    }
  }

  .ui.tabular.menu {
    border-bottom: none;

    .item {
      width: 50%;
      text-align: center;
      display: block;
      border: none;
      border-radius: 0 !important;
      color: #000;

      &.active {
        background-color: #d4d4d4;
      }
    }
  }

  div.input > input {
    border-bottom-style: none !important;
    border-top-style: none !important;
    border-left-style: none !important;
    border-right-style: none !important;
  }
}

.member-taskforce-email {
  margin-left: 10px;
}

.member-taskforce-grade {
  font-weight: 900;
  display: block;
}

.member-taskforce-item {
  padding: 15px 0 15px 15px !important;
  margin: 0 -10px;
  transition: 0.1s background-color;
  display: flex;
  align-items: center;
  color: #fff !important;

  &:hover {
    background-color: $backgroundActive;
  }

  &.active {
    background-color: $backgroundActive;
  }

  svg {
    margin-left: 10px;
  }
}

.member-taskforce-item-action {
  cursor: pointer;
  padding: 10px 15px;

  span {
    margin-left: 10px;
  }

  transition: 0.1s background-color;

  &:hover {
    background-color: $backgroundActive;
  }
}

.default-member-list {
  padding: 15px 10px !important;
  color: #fff !important;
}

.ui.segment.active.tab {
  .ui.dropdown.button {
    background-color: transparent;
    font-size: 13px;
    margin: 20px 0 0;

    .dropdown-wrapper {
      & > svg {
        margin-left: 5px;
      }

      & > * {
        margin: 10px 0 0;
      }
    }

    & > i {
      font-size: 40px;
      background-color: transparent;
      width: 40px;
      margin-left: 5px !important;

      &::before {
        width: initial;
      }
    }
  }
}

.ui.styled.accordion {
  box-shadow: unset;
}

.main-title {
  padding: 7px 15px !important;
  font-weight: 900;
  // font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-family: "Roboto", sans-serif;
  color: rgb(51, 51, 51);
}

.no-tasks {
  padding: 20px 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    color: #aaa;
  }
}

.title {
  padding: 10px 0 !important;
}

.ledger-file {
  &:hover {
    color: #c4c5c7;
  }
}

.ledger-image-large {
  max-height: calc(100vh - 100px);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
}

.modal-image-container {
  max-height: calc(100vh - 200px);
  overflow: hidden;
  border-radius: 5px;

  span {
    position: absolute;
    bottom: 30px;
    right: 40px;
    padding: 5px 10px 5px 10px;
    background-color: rgba(255, 255, 255, 0.5);
    text-align: right;
    border-radius: 5px;
  }

  p {
    padding: 0;
    margin: 0;
  }
}

.ActiveFilterChip {
  color: #fff !important;
  background-color: #333333FF !important;
  border: 0.5px solid #333333FF !important;
  border-radius: 100px !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  height: 30px;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.ledger-item-actions-small {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(255, 255, 255, 0.2);
  padding: 4px;
  max-width: 65px;
  min-width: 35px;
  border-radius: 5px;
  margin-bottom: -25px;
  position: relative;
  left: 5px;
  &.hover {
    background-color: rgba(40, 39, 39, 0.9);
  }
}

.ledger-item-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: -25px;
    background-color: rgb(255, 255, 255, 0.2);
    padding: 4px;
    max-width: 65px;
    min-width: 35px;
    border-radius: 5px;
    position: relative;
    left: 160px;
    &.hover {
      background-color: rgba(40, 39, 39, 0.9);
    }
}

.ledger-item-action {
    cursor: pointer;
    padding-left: 5px;
    padding-right: 5px;
    &:hover {
        color: #b6b3b3;
    }
}

.ledger-item-action-inactive {
  cursor: not-allowed;
  padding-left: 5px;
  padding-right: 5px;
  &:hover {
    color: #b6b3b3;
  }
}

.ledger-member-dropdown-menu {
  max-height: 50vh;
  overflow-y: auto;
}


.ledger-image-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  grid-template-rows: auto;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
  max-height: 200px;
  min-height: 100px;
  overflow-y: auto;
  margin-right: 5px;
  margin-left: 5px;
}

.ledger-image-multiple {
  max-height: 80px;
  overflow: hidden;
  border-radius: 5px;
}

.map-image {
    width: 200px;
    height: auto;
    max-height: 300px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s;
    cursor: pointer;

    // create transparent overlay on hover
    &:hover {
      background-color: rgba(0, 0, 0, 0.5);
      mask: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    }

    &.active {
      background-color: rgba(0, 0, 0, 0.5);
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
}

.ledger-image-small {
  height: auto;
  max-height: 200px;
  min-height: 80px;
  width: auto;
  min-width: 100px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
  cursor: pointer;

  // create transparent overlay on hover
  &:hover {
    background-color: rgba(0, 0, 0, 0.5);
    mask: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  }

  &.active {
    background-color: rgba(0, 0, 0, 0.5);
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.ledger-image {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
  cursor: pointer;

  // create transparent overlay on hover
  &:hover {
    background-color: rgba(0, 0, 0, 0.5);
    mask: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  }

  &.active {
    background-color: rgba(0, 0, 0, 0.5);
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.image-attachments {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
    max-height: 200px;
    min-height: 100px;
    overflow-y: auto;
    margin-right: 5px;
    margin-left: 5px;

  &.hover {
    .image-attachment {
      &:hover {
        background-color: rgba(0, 0, 0, 0.5);
      }
    }
  }
}

.image-attachment {
    position: relative;
    width: 100%;
    height: 80px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s;

    &:hover {
        background-color: rgba(0, 0, 0, 0.5);
    }

    &.active {
        background-color: rgba(0, 0, 0, 0.5);
    }

    &.disabled {
        pointer-events: none;
        opacity: 0.5;
    }

    .image-attachment-remove {
        position: absolute;
        top: 5px;
        right: 5px;
        color: #5d5c5c;
        font-size: 15px;
        min-height: 20px;
        min-width: 20px;
        max-height: 20px;
        max-width: 20px;
        cursor: pointer;
        border-radius: 50%;
        border-style: none;
        background-color: rgba(255, 255, 255, 0.5);
    }

}

.start-chat {
  padding: 40px 0 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #e5e5e5;

  svg {
    font-size: 90px;
    color: #e0f2f9;
    margin-bottom: 10px;
  }

  p {
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    color: #aaa;
  }
}

.accordion-wrapper {
  .sub-accordion {
    background-color: rgba(203, 230, 255, 0) !important;
    box-shadow: unset !important;
    .title {
      border-radius: 5px;
      cursor: pointer !important;
      padding: 10px 45px 10px 74px !important;
      color: rgba(0, 0, 0, 0.95) !important;
      display: flex !important;
      align-items: center !important;
      font-weight: 700 !important;
      margin-bottom: 10px !important;
      position: relative;
      border: none !important;
      margin-bottom: unset !important;

      & > * {
        margin: unset;
      }

      .sub-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &:hover {
        background-color: #eff9fd !important;
      }
    }

    .content {
      position: relative;
      border: none !important;
      display: block;

      & > * {
        margin: unset;
      }

      & > * {
        margin: unset;
      }

      .sub-accordion-label {
        border-radius: 5px;
        cursor: pointer;
        padding: 10px 45px 10px 84px;
        color: rgba(0, 0, 0, 0.95) !important;
        display: flex;
        align-items: center;
        gap: 15px;
        font-weight: 500;
        // margin-bottom: 10px;
        &:hover {
          background-color: #eff9fd !important;
        }
      }
      &.active {
        background-color: rgba(203, 230, 255, 0) !important;
      }

      &.disabled {
        pointer-events: none;
        background-color: rgba(241, 248, 255, 0.7) !important;
        opacity: 0.6;
      }
    }
  }
}
